<template>
  <section id="pricing-plan">
    <div class="text-center">
      <h1 class="mt-5">
        {{ titleHeader }}
      </h1>
      <p class="mb-2 pb-75">
        {{ titleHeaderPrice }}
      </p>
    </div>

    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="10"
        class="mx-auto"
      >
        <b-row>
          <b-col
            md="4"
          >
            <b-card
              align="center"
            >
              <h3>Пробная версия</h3>
              <b-card-text>
                БЕСПЛАТНО 14 дней
              </b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <span class="pricing-basic-value font-weight-bolder text-primary">0</span>
                  <sup class="font-medium-1 font-weight-bold text-primary">₽</sup>
                </div>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item>
                  1 администратор
                </b-list-group-item>
                <b-list-group-item>
                  6 сотрудников
                </b-list-group-item>
                <b-list-group-item>
                  3 Гб диск для Ваших учебных материалов
                </b-list-group-item>
                <b-list-group-item>
                  До 15 статей и 5 тестов
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                block
                class="mt-2"
                variant="outline-success"
              >
                {{ typeTarrif === 1 ? 'Ваша текущая подписка' : 'Только для новых пользователей' }}
              </b-button>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card
              class="popular"
              align="center"
            >
              <h3>Стандартный</h3>
              <b-card-text>Стандартный функционал сервиса с технической поддержкой</b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ monthlyPlanShow ? 4900:1200 }}</span>
                  <sup class="font-medium-1 font-weight-bold text-primary">₽</sup>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/месяц</sub>
                </div>
                <small
                  v-show="!monthlyPlanShow"
                  class="annual-pricing text-muted"
                > 12.500 / год</small>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item>
                  3 администратора
                </b-list-group-item>
                <b-list-group-item>
                  50 сотрудников
                </b-list-group-item>
                <b-list-group-item>
                  20 Гб диск для Ваших учебных материалов
                </b-list-group-item>
                <b-list-group-item>
                  Интеграция с Битрикс24
                </b-list-group-item>
                <b-list-group-item>
                  До 80 статей и 40 тестов
                </b-list-group-item>
                <b-list-group-item>
                  Тех. поддержка
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                class="mt-2"
                variant="primary"
                @click="payment"
              >
                Оплатить
              </b-button>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card
                class="popular"
                align="center"
            >
              <h3>Большая команда</h3>
              <b-card-text>Полный функционал сервиса с технической поддержкой</b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ monthlyPlanShow ? 8490:1200 }}</span>
                  <sup class="font-medium-1 font-weight-bold text-primary">₽</sup>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/месяц</sub>
                </div>
                <small
                    v-show="!monthlyPlanShow"
                    class="annual-pricing text-muted"
                > 12.500 / год</small>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item>
                  10 администраторов
                </b-list-group-item>
                <b-list-group-item>
                  100 сотрудников
                </b-list-group-item>
                <b-list-group-item>
                  30 Гб диск для Ваших учебных материалов
                </b-list-group-item>
                <b-list-group-item>
                  Интеграция с Битрикс24
                </b-list-group-item>
                <b-list-group-item>
                  Интеграция с AmoCRM
                </b-list-group-item>
                <b-list-group-item>
                  Генератор тестов
                </b-list-group-item>
                <b-list-group-item>
                  Тесты с ручной проверкой
                </b-list-group-item>
                <b-list-group-item>
                  До 150 статей и 80 тестов
                </b-list-group-item>
                <b-list-group-item>
                  Тех. поддержка
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  block
                  class="mt-2"
                  variant="primary"
                  @click="payment"
              >
                Оплатить
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--/ pricing plan cards -->

    <div class="pricing-free-trial">
      <b-row>
        <b-col
          lg="10"
          offset-lg="3"
          class="mx-auto"
        >
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">
                14-дневная БЕСПЛАТНАЯ версия для новых пользователей
              </h3>
              <h5>Полный доступ ко всем функциям в течение 14 дней.</h5>
            </div>

            <!-- images -->
            <b-img
              fluid
              :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
              class="pricing-trial-img"
              alt="svg img"
            />
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="pricing-faq">
      <h3 class="text-center">
        FAQ's
      </h3>
      <p class="text-center">
        Ответы на самые распространенные вопросы.
      </p>
      <b-row class="py-2">
        <b-col
          lg="10"
          offset-lg="2"
          class="mx-auto"
        >
          <app-collapse
            accordion
            type="margin"
          >

            <app-collapse-item
              v-for="(data,index) in question"
              :key="index"
              :title="data.question"
            >
              {{ data.ans }}
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import {
  BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
/* eslint-disable global-require */
export default {
  components: {
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BImg,
    AppCollapseItem,
    AppCollapse,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      status: 'monthly',
      monthlyPlanShow: true,
      pricing: {},
      question: [
        {
          question: 'Как оплатить подписку?',
          ans:
            'Для оплаты подписки Вам нужно выбрать период на который вы хотите купить подписку и нажать на кнопку оплатить. В выбранной форме выбрать заполнить данные и оплатить удобным способом',
        },
        {
          question: 'Какие способы оплаты у Вас есть',
          ans:
            'Вы можете оплатить подписку по карте или через расчетный счет',
        },
      ],
    }
  },
  computed: {
    ...mapState('main', ['user']),
    typeTarrif() {
      return Number(this.user?.tariffType)
    },
    titleHeaderPrice() {
      if (this.typeTarrif === 1) {
        return 'Вы используете бесплатную 14-дневную версию с полным функционалом'
      } if (this.typeTarrif === 2) {
        return 'Вы используете полную версию'
      } if (this.typeTarrif === 3) {
        return 'Для восстановления доступа продлите подписку'
      }

      return ''
    },
    titleHeader() {
      if (this.typeTarrif === 3) {
        return `Доступ к сервису ограничен. Ваша подсписка закончилась ${this.user.tariffEnd}`
      } if (this.typeTarrif === 2 || this.typeTarrif === 1) {
        return `Подписка до ${this.user.tariffEnd}`
      }
    },
  },
  methods: {
    tooglePlan() {
      if (this.status === 'monthly') {
        this.monthlyPlanShow = true
      } else {
        this.monthlyPlanShow = false
      }
    },
    payment() {
      window.open('https://eddy24.ru/#rec476149614', '_blank')
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
